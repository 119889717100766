import { Pipe, PipeTransform } from '@angular/core';
import { JobCategory } from '../../../__generated__/types';

@Pipe({
  name: 'jobCategory',
  standalone: true,
})
export class JobCategoryPipe implements PipeTransform {
  public transform(value: JobCategory): string {
    switch (value) {
      case JobCategory.Apprenticeship:
        return 'Ausbildung';
      case JobCategory.Fulltime:
        return 'Festanstellung';
      case JobCategory.Internship:
        return 'Praktikum';
      case JobCategory.Unknown:
        return 'Unbekannt';
      default:
        return 'Unbekannt';
    }
  }
}
